import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import LengthMenu from '../../../components/LengthMenu'
import SearchFilter from '../../../components/SearchFilter'
import TableButton from '../../../components/TableButton'
import UsersListPagination from '../../../components/TablePagination'
import { GetJobPlacement } from '../../../Functions/FGGroup'

const JobPlacement: React.FC = () => {
	const intl = useIntl()
	const [searchTerm, setSearchTerm] = useState('')
	const [jobData, setJobData] = useState<any>([])
	const [metaData, setMetaData] = useState<any>()
	const [pagination, setPagination] = useState({
		page: 1,
		itemsPerPage: 10,
	})
	const [loading, setLoading] = useState(false)

	const fetchJobData = async (page?: number) => {
		setLoading(true)
		try {
			const response = await GetJobPlacement({
				page: page || pagination.page,
				limit: pagination.itemsPerPage,
				search: searchTerm,
			})
			const metaData: any = response.metadata
			setMetaData(metaData.pagination)
			const filteredData: any = response.data
			setJobData(filteredData)
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		fetchJobData()
	}, [pagination.page, pagination.itemsPerPage])

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}

		if (searchTerm.trim() || searchTerm === '') {
			setPagination((prev) => ({ ...prev, page: 1 }))
			if (pagination.page === 1) fetchJobData()
		}
	}, [searchTerm])

	const handlePageChange = (page: number) => {
		setPagination({ ...pagination, page })
	}
	const handleItemsPerPageChange = (value: number) => {
		setPagination({ ...pagination, itemsPerPage: value })
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Job & Placement</PageTitle>
			<KTCard>
				<div className='d-flex justify-content-between mx-6 me-9'>
					<div className='d-flex pt-1 mx-2 m-5'>
						<SearchFilter
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
						/>
						<div className='mt-1'>
							<LengthMenu
								expenseData={jobData}
								handleItemsPerPageChange={handleItemsPerPageChange}
							/>
						</div>
					</div>

					<div className='d-flex align-items-center position-relative my-2'>
						<div className='modal-footer justify-content-end'>
							<TableButton
								action='add'
								to='/fgiit/job-placement-add'
								text='Add Job'
							/>
						</div>
					</div>
				</div>

				<div className='py-4 card-body'>
					<div className='table-responsive'>
						<table
							id='kt_table_users'
							className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-row-dashed table-row-gray-300 align-middle'>
							<thead>
								<tr className='fw-bold text-muted bg-light border-bottom-0'>
									<th className='ps-4 rounded-start'>No.</th>
									<th>Title</th>
									<th>Email</th>
									<th>Mobile</th>
									<th>Vacancies</th>
									<th>Clicks</th>
									<th className='ps-4 rounded-end'>Action</th>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<tr>
										<td
											colSpan={12}
											className='text-center'>
											<div className='d-flex justify-content-center align-items-center mb-4 my-7'>
												<div
													className='spinner-border text-primary'
													role='status'>
													<span className='visually-hidden'>Loading...</span>
												</div>
											</div>
										</td>
									</tr>
								) : (
									jobData
										.slice()
										.reverse()
										.map((data: any, index: any) => (
											<tr key={index}>
												<td>
													<span className='text-dark ms-6 fw-bold   mb-1 fs-6'>
														{index + 1}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold   mb-1 fs-6'>
														{data.title}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold   mb-1 fs-6'>
														{data.contact_email}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold   mb-1 fs-6'>
														{data.contact_mobile}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold   mb-1 fs-6'>
														{data.vacancies}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold   mb-1 fs-6'>
														{data?.clicks?.length || 0}
													</span>
												</td>

												<td>
													<TableButton
														action='view'
														to={`/fgiit/job-placement-view?post_id=${data._id}`}
													/>
												</td>
											</tr>
										))
								)}
							</tbody>
						</table>
					</div>
					{jobData.length === 0 && !loading && (
						<div className='d-flex text-center w-100 align-content-center justify-content-center mt-5'>
							<b>No records found</b>
						</div>
					)}
					{jobData.length > 0 && (
						<UsersListPagination
							totalPages={metaData?.totalPages}
							currentPage={pagination.page}
							onPageChange={handlePageChange}
						/>
					)}
				</div>
			</KTCard>
		</>
	)
}

export default JobPlacement
