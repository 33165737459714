import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import LengthMenu from '../../../components/LengthMenu'
import SearchFilter from '../../../components/SearchFilter'
import SelectField from '../../../components/SelectField'
import TableButton from '../../../components/TableButton'
import UsersListPagination from '../../../components/TablePagination'
import { GetProjects } from '../../../Functions/FGGroup'

const ProjectSubmission = () => {
	const [searchTerm, setSearchTerm] = useState('')
	const [userData, setUserData] = useState<any>([])
	const [selectedSubject, setSelectedSubject] = useState('')
	const [pagination, setPagination] = useState({
		page: 1,
		itemsPerPage: 10,
	})
	const [loading, setLoading] = useState(false)

	const fetchData = async () => {
		setLoading(true)
		try {
			const response: FGGroupAPIResponse = await GetProjects()

			let filteredData: any = response.data
			if (selectedSubject) {
				filteredData = filteredData.filter((lecture: any) => {
					return lecture.course.course_name === selectedSubject
				})
			}
			setUserData(filteredData)
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		fetchData()
	}, [selectedSubject])

	const handlePageChange = (page: number) => {
		setPagination({ ...pagination, page })
	}

	const handleItemsPerPageChange = (value: number) => {
		setPagination({ ...pagination, itemsPerPage: value })
	}

	const filteredUserData = userData.filter(
		(user: any) =>
			user.project_title && user.project_title.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const paginatedUserData = filteredUserData.slice(
		(pagination.page - 1) * pagination.itemsPerPage,
		pagination.page * pagination.itemsPerPage
	)

	const handleDownload = (filePath: any) => {
		const link = document.createElement('a')
		link.href = 'https://files.fggroup.in/' + filePath
		link.setAttribute('download', '')
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Project Submission</PageTitle>
			<KTCard className='mt-4'>
				<div className='card-header border-0 pt-6'>
					<div className='card-title'>
						<LengthMenu
							expenseData={userData}
							handleItemsPerPageChange={handleItemsPerPageChange}
						/>
					</div>
					<div className='card-toolbar d-flex'>
						<SelectField
							className='fv-row mb-7 me-2 mt-8'
							label='Subject'
							showLabel={false}
							name='Filter Subject'
							value={selectedSubject}
							onChange={(e) => setSelectedSubject(e.target.value)}
							htmlFor='Filter Subject'
							options={[
								'Anabolic Androgenic Steroids',
								'Diploma In Nutrition Course',
								'Nutri Trainer',
								'Injury Rehabilitation Masterclass',
								'Group Instructor Masterclass',
								'Diploma In Nutrition',
								'Advance Clinical Nutrition',
								'Mix Martial Arts Workshop',
								'Anabolic Androgenic Steroids Masterclass',
								'Functional Training Workshop',
								'Diploma In Personal Training',
								'Powerlifting Coach Workshop',
								'Diploma In Business Management',
								'Tabata Workshop',
								'Certified Nutrition Course',
								'TRX band workshop',
								'Python Programming',
								'Injury Rehabilitation Workshop',
								'Injury Rehab Course',
								'Certified Personal Trainer',
								'Flexible Learning',
								'Certified Wellness Consultant',
								'Gym Management Course',
								'Digital Marketing',
								'Tabata & Functional Trainer',
								'Tabata & Functional Workshop',
								'Group Instructor Workshop',
							]}
						/>
						<SearchFilter
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
						/>
					</div>
				</div>
				<div className='card-body py-4'>
					<div className='table-responsive'>
						<table
							id='kt_table_users'
							className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-row-dashed table-row-gray-300 align-middle'>
							<thead>
								<tr className='fw-bold text-muted bg-light border-bottom-0'>
									<th className='ps-4 rounded-start'>No.</th>
									<th>Title</th>
									<th>Project File</th>
									<th>Course</th>
									<th>Course method</th>
									<th>Category</th>
									<th className='ps-4 rounded-end'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<tr>
										<td
											colSpan={12}
											className='text-center'>
											<div className='d-flex justify-content-center align-items-center mb-4 my-7'>
												<div
													className='spinner-border text-primary'
													role='status'>
													<span className='visually-hidden'>Loading...</span>
												</div>
											</div>
										</td>
									</tr>
								) : (
									paginatedUserData
										.slice()
										.reverse()
										.map((user: any, index: number) => (
											<tr key={user.id}>
												<td>
													<span className='text-dark ms-6 fw-bold  d-block mb-1 fs-6'>
														{index + 1}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{user.project_title}
													</span>
												</td>
												<td>
													<button
														className='btn gap-2 btn-light-primary mx-2 btn-sm me-1'
														onClick={() => handleDownload(user.project_files[0])}>
														<FontAwesomeIcon icon={faCloudArrowDown} />
													</button>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{user.course?.course_name}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{user.course?.coaching_mode}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{user.course?.course_category}
													</span>
												</td>
												<td>
													<TableButton
														action='view'
														to={`/fgiit/project-submission-details?submission_id=${user._id}&course_id=${user.course?._id}`}
													/>
												</td>
											</tr>
										))
								)}
							</tbody>
						</table>
					</div>
					{paginatedUserData.length === 0 && !loading && (
						<div className='d-flex text-center w-100 align-content-center justify-content-center mt-5'>
							<b>No records found</b>
						</div>
					)}
					{paginatedUserData.length > 0 && (
						<UsersListPagination
							totalPages={Math.ceil(paginatedUserData.length / pagination.itemsPerPage)}
							currentPage={pagination.page}
							onPageChange={handlePageChange}
						/>
					)}
				</div>
			</KTCard>
		</>
	)
}
export default ProjectSubmission
