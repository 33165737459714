import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { MultiSelect } from 'react-multi-select-component'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import InputField from '../../../components/InputField'
import TableButton from '../../../components/TableButton'
import {
	DeleteJobPlacement,
	GetFitnessCourse,
	GetJobPlacement,
	GetUsers,
	UpdateJobPlacement,
} from '../../../Functions/FGGroup'
import questionmark from './300-1.jpg'
import UsersListPagination from '../../../components/TablePagination'
import { DayJS } from '../../../../_metronic/helpers/Utils'

const JobPlacementView = () => {
	const intl = useIntl()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const post_id: string | any = searchParams.get('post_id')

	const [courseData, setCourseData] = useState<any>([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const navigate = useNavigate()
	const [foodAddData, setFoodAddData] = useState({
		title: '',
		contact_person: '',
		website: '',
		contact_email: '',
		contact_mobile: '',
		company_name: '',
		vacancies: '',
		end_date: '',
		related_course: [],
		createdAt: '',
		updatedAt: '',
	})
	const [clickData, setClickData] = useState<any>([])
	const [loading, setLoading] = useState(false)
	const [pagination, setPagination] = useState({
		page: 1,
		itemsPerPage: 10,
	})

	const fetchUserData = async () => {
		setLoading(true)
		try {
			const response = await GetJobPlacement({ post_id })
			const filteredData: any = response.data[0]
            
			const userData = await Promise.all(
				filteredData.clicks.map(async (user: any) => {
					const user_response = await GetUsers({ id: user.user })
					return { ...user, userData: user_response.data[0] }
				})
			)
            setClickData(userData)

			const course_response = await GetFitnessCourse()
			let coursefilterData: any = course_response.data
			coursefilterData = coursefilterData.map((course: any) => {
				return {
					value: course.course_name,
					label: `${course?.course_name} - ${course?.course_category}`,
					id: course._id,
				}
			})
			setCourseData(coursefilterData)

			const coursesWithNames = filteredData.related_course.map((courseId: any) => {
				const matchingCourse = coursefilterData.find((course: any) => course.id === courseId)
				return matchingCourse
					? { label: matchingCourse.label, value: matchingCourse.value, id: matchingCourse.id }
					: { label: courseId, value: courseId, id: courseId }
			})
			filteredData.related_course = coursesWithNames

			setFoodAddData(filteredData)
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		fetchUserData()
	}, [])
    
	const handlePageChange = (page: number) => {
		setPagination({ ...pagination, page })
	}

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) => {
		const { id, name, value } = event.target

		setFoodAddData((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handleMultiSelectChange = (selected: any) => {
		setFoodAddData((prevData) => ({
			...prevData,
			related_course: selected,
		}))
	}

	const handleUpdateButtonClick = async () => {
		try {
			setIsSubmitting(true)

			const course = foodAddData.related_course.map((el: any) => {
				return el.value
			})

			const payload: any = {
				post_id: post_id,
				title: foodAddData.title,
				contact_person: foodAddData.contact_person,
				website: foodAddData.website,
				email: foodAddData.contact_email,
				mobile: foodAddData.contact_mobile,
				company_name: foodAddData.company_name,
				vacancies: foodAddData.vacancies,
				end_date: foodAddData.end_date,
				related_course: course,
			}

			await UpdateJobPlacement(payload)

			// navigate('/fgiit/job-placement')

			toast.success('Placement Updated Successfully')
			setIsSubmitting(false)
		} catch (error: any) {
			toast.error(error.message)
			setIsSubmitting(false)
			console.error(error)
		}
	}

	const deleteLecture = async () => {
		try {
			await DeleteJobPlacement({ post_id })

			toast.success('Placement remove successfully')

			navigate('/fgiit/job-placement')
		} catch (error: any) {
			toast.error(error.message)
			console.error(error)
		}
	}

    const paginatedLeadData = clickData.slice(
		(pagination.page - 1) * pagination.itemsPerPage,
		pagination.page * pagination.itemsPerPage
	)

	return (
		<>
			<>
				<PageTitle breadcrumbs={[]}>Placement Details</PageTitle>
				<div className='row mb-9'>
					<div className='col-12 mt-3'>
						<div className='card py-10'>
							<div className='card-body'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='row'>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Placement Title'
													type='text'
													className='col-12 fv-row mb-7'
													name='title'
													label='Placement Title'
													htmlFor='title'
													value={foodAddData.title}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Contact Person name'
													type='text'
													className='col-12 fv-row mb-7'
													name='contact_person'
													label='Contact Person name'
													htmlFor='contact_person'
													value={foodAddData.contact_person}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Website'
													type='text'
													className='col-12 fv-row mb-7'
													name='website'
													label='Website'
													htmlFor='website'
													value={foodAddData.website}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Email'
													type='text'
													className='col-12 fv-row mb-7'
													name='contact_email'
													label='Email'
													htmlFor='contact_email'
													value={foodAddData.contact_email}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Mobile'
													type='text'
													className='col-12 fv-row mb-7'
													name='contact_mobile'
													label='Mobile'
													htmlFor='contact_mobile'
													value={foodAddData.contact_mobile}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Company Name'
													type='text'
													className='col-12 fv-row mb-7'
													name='company_name'
													label='Company Name'
													htmlFor='company_name'
													value={foodAddData.company_name}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Vacancies'
													type='number'
													className='col-12 fv-row mb-7'
													name='vacancies'
													label='Vacancies'
													htmlFor='vacancies'
													value={`${foodAddData.vacancies}`}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='End Date'
													type='text'
													className='col-12 fv-row mb-7'
													name='end_date'
													label='End Date'
													htmlFor='end_date'
													value={foodAddData.end_date}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<label
													className='required fw-bold fs-6 mb-2'
													htmlFor='Select'>
													Courses
												</label>
												<MultiSelect
													options={courseData}
													value={foodAddData.related_course}
													onChange={handleMultiSelectChange}
													labelledBy='Select'
													className='fv-row'
												/>
											</div>
											<div className='col-md-3 fv-row mb-7'>
												<InputField
													placeholder='Created On'
													type='text'
													className='col-12 fv-row mb-7'
													name='createdAt'
													label='Created On'
													htmlFor='createdAt'
													value={foodAddData.createdAt}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-3 fv-row mb-7'>
												<InputField
													placeholder='Last Modification'
													type='text'
													className='col-12 fv-row mb-7'
													name='updatedAt'
													label='Last Modification'
													htmlFor='updatedAt'
													value={foodAddData.updatedAt}
													onChange={handleInputChange}
												/>
											</div>

											<div className='col-md-12 fv-row mt-4 mb-2 d-flex justify-content-end'>
												<TableButton
													action='edit'
													onClick={handleUpdateButtonClick}
													text={isSubmitting ? 'Please wait, Update...' : 'Update'}
													showIcon={false}
													disabled={isSubmitting}
													backgroundDark={true}
													className={`mb-4 btn-block ${isSubmitting ? 'disabled' : ''}`}
												/>
												<div
													data-bs-toggle='modal'
													data-bs-target='#kt_modal_exercise'>
													<TableButton
														action='remove'
														text='Remove user'
														showIcon={false}
														backgroundDark={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<KTCard>
					<h2 className='fw-bold text-dark fs-1 ms-9 mt-5 mb-2 '>
						Clicks Details
					</h2>
					<div className='py-4 card-body'>
						<div className='table-responsive'>
							<table
								id='kt_table_users'
								className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-row-dashed table-row-gray-300 align-middle'>
								<thead>
									<tr className='fw-bold text-muted bg-light border-bottom-0'>
										<th className='ps-4 rounded-start'>No.</th>
										<th>User ID</th>
										<th>Name</th>
										<th>Mobile</th>
										<th>Email</th>
										<th>Date</th>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<tr>
											<td
												colSpan={12}
												className='text-center'>
												<div className='d-flex justify-content-center align-items-center mb-4 my-7'>
													<div
														className='spinner-border text-primary'
														role='status'>
														<span className='visually-hidden'>Loading...</span>
													</div>
												</div>
											</td>
										</tr>
									) : (
										paginatedLeadData
											.slice()
											.reverse()
											.map((click: any, index: any) => (
												<tr key={index}>
													<td>
														<span className='text-dark ms-6 fw-bold   mb-1 fs-6'>
															{index + 1}
														</span>
													</td>
													<td>
														<span className='text-dark fw-bold   mb-1 fs-6'>
															{click?.user}
														</span>
													</td>
													<td>
														<span className='text-dark fw-bold   mb-1 fs-6'>
															{click?.userData?.first_name + ' ' + click?.userData?.last_name}
														</span>
													</td>
													<td>
														<span className='text-dark fw-bold   mb-1 fs-6'>
															{click?.userData?.country_code + ' ' + click?.userData?.mobile}
														</span>
													</td>
													<td>
														<span className='text-dark fw-bold   mb-1 fs-6'>
															{click?.userData?.email}
														</span>
													</td>
													<td>
														<span className='text-dark fw-bold   mb-1 fs-6'>
															{DayJS(click?.date).format('DD/MM/YYYY hh:mm A')}
														</span>
													</td>
												</tr>
											))
									)}
								</tbody>
							</table>
						</div>
						{clickData.length === 0 && !loading && (
							<div className='d-flex text-center w-100 align-content-center justify-content-center mt-5'>
								<b>No records found</b>
							</div>
						)}
						{clickData.length > 0 && (
							<UsersListPagination
								totalPages={Math.ceil(clickData.length / pagination.itemsPerPage)}
								currentPage={pagination.page}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</KTCard>

				<div
					className='modal fade'
					id='kt_modal_exercise'
					aria-hidden='true'>
					<div className='modal-dialog modal-dialog-centered mw-600px'>
						<div className='modal-content border-0'>
							<div className='modal-header border-0 justify-content-end'>
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'></button>
							</div>
							<div className='text-center mb-4'>
								<img
									src={questionmark}
									alt='Question Mark'
									style={{ width: '80px', height: '80px' }}
								/>
								<h2
									className='fw-bold mt-3'
									style={{ fontSize: '25px' }}>
									Are you sure?
								</h2>
							</div>
							<div className='modal-body p-5'>
								<h3 className='text-center fw-20'>
									This user will be removed from the portal and will no longer access
								</h3>
							</div>
							<div className='modal-footer border-0 d-flex justify-content-center'>
								<button
									className='btn btn-danger me-3 fs-24'
									onClick={() => deleteLecture()}
									style={{ padding: '12px 24px', fontSize: '20px' }}>
									<FontAwesomeIcon
										className='me-2'
										icon={faCheck}
									/>
									Okay
								</button>
								<button
									className='btn btn-success fs-29'
									data-bs-dismiss='modal'
									style={{ padding: '12px 24px', fontSize: '20px' }}>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	)
}
export { JobPlacementView }
